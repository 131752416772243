'use client'

import React, { ComponentType, useState } from 'react'
import { Spinner } from '@/components/ui-other/basic/Spinner'
import Button from '@/components/ui-other/basic/Button'
import { postEmailConfirmationResend } from 'data/authentication'

type ResendVerificationEmailProps = {
  token: string
}

export const ResendVerificationEmail: ComponentType<
  ResendVerificationEmailProps
> = ({ token }) => {
  const [state, setState] = useState({
    loading: false,
    success: false,
    error: false
  })

  const resendEmail = () => {
    setState((prevState) => ({
      loading: true,
      success: false,
      error: false
    }))
    postEmailConfirmationResend({ token })
      .then((resp) => {
        setState({
          loading: false,
          success: resp.valid,
          error: !resp.valid
        })
      })
      .catch(() =>
        setState({
          loading: false,
          success: false,
          error: true
        })
      )
  }
  return (
    <div className="view-horizontal space-x-2 items-center">
      {(!state.success || state.error) && (
        <Button color="primary-outline" onClick={resendEmail}>
          E-Mail nochmal versenden
        </Button>
      )}
      {!state.loading && state.success && <span>E-Mail wurde versandt</span>}
      <Spinner loading={state.loading} />
      {!state.loading && state.error && (
        <span className="text-primary">Es ist ein Fehler aufgetreten</span>
      )}
    </div>
  )
}
